export default {
  title: `<span class="text">EBC 글로벌 실거래 대회 Ⅱ</span>`,
  title2: ``,
  巴萨传奇再启: "새로운 전설의 시작, 다음 제왕을 찾아라",
  成就交易之王: "",
  大赛时间: "대회 기간: 2025년 3월 1일 ~ 2025년 5월 30일",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "원-클릭 카피트레이딩",
  免费复制冠军收益: "챔피언의 트레이딩 무료로 카피하기",
  公开透明: "공정하고 투명하게",
  观摩冠军交易技巧: "챔피언의 거래 기술 배우기",
  推荐有礼: "초대 이벤트",
  推荐有奖上不封顶: "제한 없는 이벤트 보상받기",
  立即参赛: "참가신청",
  现金奖池: "현금 상금액",
  巴萨梦之队: "엘리트 팀",
  拉玛西亚组: "루키 팀",
  冠军: "1위",
  亚军: "2위",
  季军: "3위",
  第四至五名: "4위 ~ 5위",
  第六至十名: "6위 ~ 10위",
  "$1,000,000交易账号或$200,000现金": "100만 달러 거래 계좌 or 20만 달러 현금",
  "1,000,000交易账号或200,000现金": "100만 달러 거래 계좌 or 20만 달러 현금",
  大赛推荐官: "초대 이벤트",
  推荐奖励: "한도 없이 거래하고 추천 보상을 획득하세요!",
  巴萨梦之队HTML: `엘리트 팀`,
  拉玛西亚组HTML: `루키 팀`,
  "/人": "인당",
  活动时间: "2025년 3월 1일 06:00:00 ~ 2025년 5월 1일 05:59:59 (GMT+9)",
  推荐流程: "간단한 3단계: 추천, 거래, 수익 창출!",
  推荐好友: "친구 초대",
  好友参赛: "회원가입",
  赢得奖金: "보상 받기",
  实时赛况: "대회 실황",
  排名: "랭킹",
  昵称: "닉네임",
  服务器: "서버",
  账号: "계좌",
  收益额: "순수익",
  收益率: "수익률",
  奖金: "상금",
  跟单: "카피 트레이딩",
  该页面仅展示收益表现排名前10的交易账号:
    "*해당 페이지는 10위 내 계좌만 표시합니다. 금액 단위는 USD입니다. 랭킹은 매 1시간마다 갱신됩니다. ",
  赛事进程: "대회 절차",
  比赛开始: "대회 시작",
  推荐结束: "초대 이벤트 종료",
  比赛结束: "대회 종료",
  颁奖典礼: "결과 발표",
  赛组规则: "대회 규정",
  类别: "",
  账号要求: "계좌 요건",
  "新交易账号，且账号余额大于$500":
    "새로 개설한 계좌이며 잔액이 500달러 이상이어야 합니다. ",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "새로 개설한 계좌이며 잔액이 1만 달러 이상, 20만 달러 이하여야 합니다. ",
  排名规则: "랭킹 규정",
  以收益率排名为评判标准: "순위는 수이률을 기준으로 판정됩니다. ",
  "收益率=期末净值/账号累积入金": "수익률 =  최종 순자산 / 총 계좌 예탁금",
  以净盈利额排名为评判标准: "순위는 순이익을 기준으로 판정됩니다. ",
  持仓限制: "포지션 제한",
  每个参赛账号最多持有100手仓位:
    "각 참여 계좌가 보유할 수 있는 최대 포지션은 100랏입니다.",
  无持仓限制: "포지션 제한 없음",
  参赛条款: "대회 규정",
  参赛须知: "주의 사항",
  暂未开赛:"곧 출시됩니다. 계속 지켜봐 주시기 바랍니다",
  section6List: [
    {
      t: "1. 기간",
      c: [
        "대회 기간: 2025년 3월 1일 06:00:00 ~ 2025년 5월 1일 05:59:59 (GMT+9)",
      ],
    },
    {
      t: "2. 계좌 요건",
      c: [
        "참가 계좌는 거래 커뮤니티에 시그널 등록을 해야합니다. 단, 타인의 시그널을 팔로우 할 수 없으며, 본인의 팔로우 허용은 선택 사항입니다.",
        "참가 계좌 개수에 제한은 없으나, 순위에 반영되는 계좌는 성과가 가장 우수한 계좌에 한합니다. ",
        "STD 및 PRO 계좌만 참가가 가능하며, PAMM 계좌와 이슬람 계좌는 참가할 수 없습니다.",
      ],
    },
    {
      t: "3. 보상 규정",
      c: [
        "1위 보상: 100만 달러의 거래 계좌를 선택한 경우, 해당 계좌에 최대 20만 달러 손실액이 있을 수 있습니다.",
        "그 외의 상금은 순위에 따라 차등 지급됩니다.",
      ],
    },
    {
      t: "4. 금지 사항",
      c: [
        "여러 계좌를 이용한 악의적인 헤징으로 부당한 성과를 얻는 행위는 금지입니다. ",
        "서버 하드웨어 오류나 가격 오류 등 불가피한 상황으로 발생한 주문은 무효로 처리됩니다.",
      ],
    },
    {
      t: "5. 최종 해석권",
      c: ["본 대회의 최종 해석권은 EBC에 있습니다."],
    },
  ],
  参赛流程: "참가 방법",
  注册开户: "계좌 개설",
  注册并开设新交易账号: "회원가입 후 새로운 거래 계좌를 개설하기",
  报名参赛: "참가 신청",
  在用户中心报名参赛: "사용자 센터에서 대회 참가 신청하기",
  参赛成功: "신청 완료",
  参赛成功查看实时赛况: "대회에 참가해 실황 확인하기",
  往届回顾: "이전 대회",
  第一届交易大赛颁奖典礼: "제1회 실거래 대회 시상식",
  第一届交易大赛高光时刻: "제1회 실거래 대회 하이라이트",
  第一届交易大赛巅峰对决: "제1회 실거래 대회 결승전",
  查看更多: "더 보기",
  常见问题: "FAQ",
  qaList: [
    {
      t: `1. "계정"과 "계좌"의 차이가 무엇인가요?`,
      c: [
        "계정: EBC 사용자 센터에서 계정(UID)을 의미하며, 각 사용자는 실명 인증을 거쳐야만 계정을 만들 수 있습니다. ",
        "계좌: MT 거래 계좌를 의미하며, EBC 계정 하나당 여러 거래 계좌를 개설할 수 있습니다. ",
      ],
    },
    {
      t: "2. 대회에 신청됐는지 어떻게 확인하나요?",
      c: [
        "대회 신청이 완료되면 사용자 센터 이벤트 페이지에 참가 계좌 및 현 랭킹이 표시됩니다.",
      ],
    },
    {
      t: "3. 여러 계좌로 참가할 수 있나요?",
      c: [
        "여러 계좌로 두 개의 팀에 참가할 수 있으나, 각 계좌 당 참가할 수 있는 팀은 하나입니다.",
      ],
    },
    {
      t: "4. 동시에 두 팀에서 수상할 수 있나요?",
      c: [
        "가능합니다. 단, 두 팀에서 모두 정식으로 대회를 완료하고 순위권에 들어야 합니다.",
      ],
    },
    {
      t: "5. 이미 있는 MT5 계좌로 참가할 수 있나요?",
      c: [
        "대회에 참가할 계좌는 반드시 2025년 2월 1일 전에 개설했으며 거래 이력이 없는 계좌여야 합니다. ",
      ],
    },
    {
      t: "6. 루키 팀에 참가한 뒤, 1만 달러 이상을 입금하면 팀이 바뀌나요?",
      c: [
        "아니오. 참가 팀은 신청 시점에 결정되며 이후 자금 변동은 팀에 영향을 주지 않습니다. ",
      ],
    },
    {
      t: "7. 순위는 어떻게 보나요?",
      c: [
        "랭킹은 1시간(정시가 아닌 매시간)마다 갱신되며, 사용자 센터 대회 페이지에서 확인할 수 있습니다. ",
      ],
    },
    {
      t: "8. 대회 진행 중에 자금 입출금 할 수 있나요?",
      c: [
        "가능합니다. 다만 입금 시 수익률 계산에 영향이 있을 수 있습니다. (수익률 = 최종 순자산 / 총 계좌 예탁금)",
      ],
    },
    {
      t: "9. 초대 이벤트 보상은 어떻게 받나요?",
      c: [
        "대회 종류 후 7영업일 이내에 초대된 사용자의 대회 상황을 확인 후 보상을 지급합니다. 확인 절차를 위해 고객 서비스 팀에서 연락이 갑니다. ",
      ],
    },
    {
      t: "10. 왜 대회 기간에 초대된 참가자 에이전트를 이전할 수 없나요?",
      c: [
        "형평성을 보장하고 참가자가 수수료와 초대 보너스를 동시에 얻는 행위를 방지하고자 초대된 참가자는 추천인 링크를 통해서만 대회에 참가할 수 있으며, 에이전트를 지정할 수 없습니다. 대회 종료 후 특정 에이전트를 지정하고자 한다면 고객 서비스 팀에 연락해 주세요. ",
      ],
    },
  ],
};