export default {
    "box-name": "cn-trading-body",
    lang:"cn",
    了解指数差价合约:"了解指数差价合约",
    "public-btn-text1": "查看全部交易产品",
    "public-btn-text2": "查看点差和全部产品",
    screen0: {
        "title": "交易产品",
        "desc": "超百种热门金融产品和极具竞争力的机构定价，带您进入全球最受欢迎且流动性最高的市场，掌控24/7的最佳交易机会。",
        "btn": "了解更多",
    },
    screen1: {
        "link1": "衍生品交易",
        "link2": "贵金属及能源",
        "link3": "全球股指CFDs",
        "link4": "美国股票CFDs",
    },
    screen2: {
        "title": "衍生品交易",
        "text1": "杠杆率高达500:1",
        "text2": "平均执行速度20ms",
        "imgUrl": "trade-01",
        "title2": "最低0点差即可交易<br>36种货币对",
        "desc2": "全球最大的金融交易市场 2019年9月国际清算银行（BIS）报告显示，全球衍生品市场交易量已跃升至历史最高记录，日均交易量达6.6万亿美元。",
    },
    screen3: {
        "title": "贵金属及能源",
        "text1": "灵活的杠杆",
        "text2": "极具竞争力的点差",
        "text3": "灵活建仓交易",
        "title2": "想投资组合多元化，贵金属及能源会是您的理想选择",
        "desc2": "贵金属、原油、天然气等。",
    },
    screen4: {
        "title": "全球股指CFDs",
        "text1": "实时弹性定价",
        "text2": "平均执行速度20ms",
        "text3": "杠杆率高达100:1",
        "text4": "无隐藏佣金和交易费用",
        "title2": "为您甄选全球主流指数，积极探索全球经济中最有价值和最活跃的领域",
    },
    screen5: {
        "title": "美国股票CFDs",
        "text1": "极具竞争力的交易成本",
        "text2": "精选 51 只热门美股",
        "text3": "超乎寻常的流动性支持",
        "text4": "支持多空双向交易",
        "title2": "股票差价合约，轻松将世界顶级公司纳入您的投资组合",
    },
}