export default {
  linkList: [
    {
      title: "帳戶及條件",
      list: [
        {
          label: "交易賬戶",
          link: "/TradingAccount",
        },
        {
          label: "交易產品",
          link: "/tradingproduct",
        },
        {
          label: "槓桿保證金",
          link: "/leverage-margin",
        },
        {
          label: "出入金",
          link: "/funding",
        },
        {
          label: "股息",
          link: "/dividend",
        },
      ],
    },
    {
      title: "交易工具",
      list: [
        {
          label: "MT4下載",
          link: "/MT4",
        },
        {
          label: "MT5下載",
          link: "/MT5",
        },
        {
          label: "MT4交易工具",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "市場分析",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "EBC研究院",
      list: [
        {
          label: "學習中心",
          link: "/LearningCenter",
        },
        {
          label: "資料中心",
          link: "/lab",
        },
      ],
    },
    {
      title: "最新活動",
      list: [
        {
          label: "積分商城",
          link: "/activity?index=0",
        },
        {
          label: "市集活動",
          link: "/activity?index=1",
        },
        {
          label: "品牌動態",
          link: "/activity?index=2",
        },
      ],
    },
    {
      title: "關於EBC",
      list: [
        {
          label: "EBC介紹",
          link: "/about-us",
        },
        {
          label: "法律文件",
          link: "/LegalDocumen",
        },
        {
          label: "社會責任",
          link: "/ESG",
        },
        {
          label: "幫助中心",
          link: "https://support.ebchelp.com/hc/zh-tw",
        },
        {
          label: "官方驗證",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "合作夥伴",
      list: [
        {
          label: "影響力聯盟",
          link: "/affiliate-programmes",
        },
        {
          label: "機構服務",
          link: "/service",
        },
        {
          label: "底層科技",
          link: "/technology",
        },
      ],
    },
  ],
  context: `
      <p>
        <span class="page-footer-intro-blod">
          EBC金融集團是由以下公司集團共享的聯合品牌<br />
        </span>
        EBC Financial Group (SVG) LLC
        在聖文森與格林納丁斯金融服務管理局註冊並授權運營，註冊號碼為353 LLC
        2020。<br />

        EBC Financial Group (Comoros) Limited
        經科摩羅聯盟昂儒昂自治島離岸金融管理局授權，許可證號為 L
        15637/EFGC，註冊辦公地址為 Hamchako, Mutsamudu, Autonomous Island of
        Anjouan, Union of Comoros。
      </p>
      <p>
        <span class="page-footer-intro-blod">其他相關實體：<br /></span>

        EBC Financial Group (UK) Limited
        由英國金融行為監管局(FCA)授權和監管，監管編號：927552，網址：<a
          target="_blank"
          href="https://www.ebcfin.co.uk"
          rel="nofollow"
          >www.ebcfin.co.uk</a
        >
      <br />
        EBC Financial Group (Cayman)
        Limited由開曼群島金融管理局(CIMA)授權和監管，監管編號：2038223，網址：
        <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow"
          >www.ebcgroup.ky</a
        >
        <br />
        EBC Group (Cyprus) Ltd，為 EBC Financial Group
        結構內的持牌和受監管實體提供支付服務，根據塞浦路斯共和國公司法註冊，編號為
        HE 449205，註冊辦公地址為 101 Gladstonos, Agathangelou Business Centre,
        3032塞浦路斯利馬索爾。
        <br />
        EBC Financial Group (Australia) Pty Ltd（公司編號：619 073
        237）由澳洲證券和投資委員會(ASIC)授權和監管（監管編號：500991），是EBC
        Financial Group (SVG) LLC
        的關聯實體，兩個實體獨立營運管理。本網站提供的金融產品和服務並非由澳洲公司實體提供，不涉及該實體的責任。
      </p>
      <p>
        <span class="page-footer-intro-blod"> 公司地址： </span>
        The Leadenhall Building, 122 Leadenhall Street, London, United Kingdom
        EC3V 4AB；郵寄地址：cs@ebc.com；電話：+44 20 3376 9662
        
      </p>
      <p>
        <span class="page-footer-intro-blod">免責聲明：</span
        >EBC金融集團明確不涉及任何虛擬貨幣業務，也不提供與虛擬貨幣相關的CFD服務，我們提供的金融服務僅限於傳統金融產品。請客戶注意，任何以EBC名義推薦虛擬貨幣相關服務的行為均為誤導，且未經授權。任何過往表現都不代表未來表現，網站內容僅供參考，不構成任何投資建議。
      </p>
      <p>
        <span class="page-footer-intro-blod">區域限制：</span
        >EBC不提供以下地區的居民服務，阿富汗、白俄羅斯、緬甸、加拿大、中非共和國、剛果、古巴、剛果民主共和國、厄立特里亞、歐盟、海地、伊朗、伊拉克、黎巴嫩、利比亞、馬來西亞、馬利、北韓（朝鮮民主主義人民共和國）、俄羅斯、索馬利亞、蘇丹、西班牙、南蘇丹、敘利亞、烏克蘭（包括克里米亞、頓內茨克和盧甘斯克地區）、美國、委內瑞拉和葉門。想了解更多，請查閱常見問題。<br />
        本網站上發布的任何西班牙語僅適用於拉丁美洲國家，不適用於歐盟和西班牙。
      </p>
      <p>
        <span class="page-footer-intro-blod">合規揭露：</span
        >公司網站在全球範圍內可見，不針對任何特定實體。您的權利義務將依據您所在地區的監管及適用的實體決定。當地法律和法規可能限製或禁止您存取、下載、傳播、分享或使用網站上發布的任何文件及資訊。
      </p>
      <p>
        <span class="page-footer-intro-blod">高風險交易產品揭露：</span
        >CFD是一種複雜的金融產品，使用槓桿伴隨高風險，可能導致迅速虧損。保證金交易風險較大，可能不適合所有投資者。您應結合自身目標、財務狀況和風險承受能力，認真評估相關資訊。您可能面臨部分或全部本金損失的風險，我們建議您尋求專業建議，以確保在做出投資決策之前充分理解相關風險。在開始交易前，請務必詳細閱讀相關的風險揭露聲明。
      </p>
  `,
};
