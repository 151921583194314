export default {
  title: `<span class="text ">แข่งเทรด EBC Million Dollar Challenge II</span>`,
  title2: ``,
  巴萨传奇再启:
    "เส้นทางสู่เงินล้าน ปลดล็อคบัลลังก์ราชาแห่งการเทรด",
  成就交易之王: "",
  大赛时间:"ระยะเวลาแข่งขัน: 1 มี.ค. 2025 เวลา 01:00:00 (เวลา MT4/MT5) - 30 พ.ค. 2025 เวลา 23:59:59 (เวลา MT4/MT5)",
  距开赛: ``,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "ติดตามสัญญาณด้วยคลิกเดียว",
  免费复制冠军收益: "ติดตามสัญญาณด้วยคลิกเดียว",
  公开透明: "เปิดเผยอย่างโปร่งใส",
  观摩冠军交易技巧: "ศึกษากลยุทธ์การเทรดของแชมป์",
  推荐有礼: "แนะนำเพื่อนรับรางวัล",
  推荐有奖上不封顶: "แนะนำเพื่อนไม่มีจำกัด",
  立即参赛: "เข้าร่วมแข่งขัน",
  现金奖池: "รางวัลเงินสด",
  巴萨梦之队: "All Star Challenge",
  拉玛西亚组: "New Star Challenge",
  冠军: "รางวัลชนะเลิศอันดับ 1",
  亚军: "รางวัลอันดับ 2",
  季军: "รางวัลอันดับ 3",
  第四至五名: "รางวัลอันดับ 4-5",
  第六至十名: "รางวัลอันดับ 6-10",
  "$1,000,000交易账号或$200,000现金":
    "บัญชีเทรดมูลค่า 1,000,000$  หรือ เงินสด 200,000$",
  "1,000,000交易账号或200,000现金":
    "บัญชีเทรดมูลค่า 1,000,000$  หรือ เงินสด 200,000$",
  大赛推荐官: "โปรแกรมผู้แนะนำ",
  推荐奖励: "รับรางวัลแนะนำได้ ไม่จำกัดจำนวน",
  巴萨梦之队HTML: `All Star Challenge`,
  拉玛西亚组HTML: `New Star Challenge`,
  "/人": "/คน",
  活动时间:
    "ระยะเวลากิจกรรม 22 ก.พ. 2025 เวลา 01:00:00 (เวลา MT4/MT5) - 23 พ.ค. 2025 เวลา 23:59:59 (เวลา MT4/MT5)",
  推荐流程: "แนะนำง่ายๆ 3 ขั้นตอน รับเงินรางวัลทันที",
  推荐好友: "แนะนำเพื่อน",
  好友参赛: "เพื่อนเข้าร่วมแข่งขัน",
  赢得奖金: "รับรางวัล",
  实时赛况: "ตารางอันดับเรียลไทม์",
  排名: "อันดับ",
  昵称: "รายชื่อผู้แข่งขัน",
  服务器: "เซิร์ฟเวอร์",
  账号: "เลขที่บัญชี",
  收益额: "อัตราผลกำไร",
  收益率: "Profit Rate (%)",
  奖金: "เงินรางวัล",
  跟单: "Copy Trading",
  该页面仅展示收益表现排名前10的交易账号:
    "*หน้านี้จะแสดงอันดับบัญชีที่มีผลกำไรสูงสุด 10 อันดับแรก หน่วยเงินที่ใช้ในการจัดอันดับ คือดอลลาร์สหรัฐ (USD) และจะมีการอัปเดตรายชั่วโมง",
  赛事进程: "ไทม์ไลน์การแข่งขัน",
  比赛开始: "เริ่มต้นแข่งขัน",
  推荐结束: "สิ้นสุดการแนะนำ",
  比赛结束: "สิ้นสุดการแข่งขัน",
  颁奖典礼: "พิธีมอบรางวัล",
  赛组规则: "เงื่อนไขการแข่งขัน",
  类别: "ประเภท",
  账号要求: "ข้อกำหนดบัญชี",
  "新交易账号，且账号余额大于$500":
    "เปิดบัญชีใหม่ และมียอดเงินคงเหลือในบัญชีมากกว่า 500$",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "เปิดบัญชีใหม่ และมียอดเงินคงเหลือในบัญชีระหว่าง 10,000$ ถึง 200,000$",
  排名规则: "เกณฑ์จัดอันดับ",
  以收益率排名为评判标准: "การจัดอันดับจะวัดจากอัตราผลตอบแทน (ROI)",
  "收益率=期末净值/账号累积入金":
    "ROI = มูลค่าหลังหักลบกำไรหรือขาดทุน / ยอดฝากคงเหลือ",
  以净盈利额排名为评判标准: "การจัดอันดับวัดจากกำไรสุทธิ",
  持仓限制: "ข้อจำกัดการออกออเดอร์",
  每个参赛账号最多持有100手仓位:
    "ทุกประเภทบัญชีออกล็อตได้สูงสุด 100 ล็อต",
  无持仓限制: "ออกออเดอร์ได้ไม่จำกัด",
  参赛条款: "เงื่อนไขการแข่งขัน",
  参赛须知: "ข้อควรระวัง",
  暂未开赛:"暂未开赛，敬请期待。",
  section6List: [
    {
      t: "1. ระยะการแข่งขัน",
      c: [
        "1 มี.ค. 2025 เวลา 01:00:00 (เวลา MT4/MT5) - 30 พ.ค. 2025 เวลา 23:59:59 (เวลา MT4/MT5)",
      ],
    },
    {
      t: "2. ข้อกำหนดบัญชี",
      c: [
        "บัญชีที่เข้าร่วมต้องแสดงสัญญาณในชุมชนเทรด และเปิดให้นักเทรดคนอื่นคัดลอกสัญญาณได้ฟรี",
        "ผู้เข้าแข่งขันสามารถใช้หลายบัญชีในการแข่งขัน แต่จะเลือกเฉพาะบัญชีที่มีผลการเทรดดีที่สุดในการจัดอันดับ",
        "เพื่อความเป็นธรรมใรการแข่งขัน อนุญาตให้บัญชี STD และ PRO ใช้เข้าร่วมการแข่งขันเท่านั้น ในส่วนบัญชี PAMM และบัญชีอิสลามไม่มีสิทธิ์เข้าร่วมการแข่งขันในครั้งนี้",
      ],
    },
    {
      t: "3. รายละเอียดรางวัล",
      c: [
        "รางวัลชนะเลิศอันดับ 1 : หากผู้ชนะเลือกรับรางวัลเป็นบัญชีเทรดมูลค่า 1,000,000 USD ซึ่งจำกัดการขาดทุนสูงสุด ไม่เกิน 200,000 USD ",
        "ในส่วนรางวัลอื่นๆ จะมอบตามลำดับการจัดอันดับ ",
      ],
    },
    {
      t: "4. ข้อห้ามการแข่งขัน",
      c: [
        "ห้ามใช้บัญชีเทรดอื่นๆ ในทางมิชอบ เพื่อให้บัญชีที่เข้าแข่งขันได้ผลกำไรที่มากขึ้น เช่น การเทรดอาร์บิทราจระหว่างบัญชี เพื่อเพิ่มผลกำไร การเปิดสถานะข้ามบัญชี การเทรดแบบป้องกันความเสี่ยง หรือการใช้ประโยชน์จากการเสนอราคา  ซึ่งจะส่งผลให้ถูกตัดสิทธิ์จากการแข่งขันทันที",
        "คำสั่งซื้อขายที่เกิดจากปัญหาของเซิร์ฟเวอร์ หรือข้อผิดพลาดของราคาจะถือเป็นโมฆะ ",
      ],
    },
    {
      t: "5. คำชี้แจงผู้ถือสิทธิ์",
      c: [
        "EBC Financial Group มีสิทธิ์ในการตีความและตัดสิน ทุกกรณีที่เกี่ยวข้องกับการแข่งขันครั้งนี้",
      ],
    },
  ],
  参赛流程: "ขั้นตอนเข้าร่วมแข่งขัน",
  注册开户: "ลงทะเบียนเปิดบัญชี",
  注册并开设新交易账号: "ลงทะเบียนเปิดบัญชีเทรดใหม่",
  报名参赛: "สมัครเข้าร่วมแข่งขัน",
  在用户中心报名参赛: "ลงทะเบียนเข้าร่วมที่ศูนย์ผู้ใช้",
  参赛成功: "เข้าร่วมแข่งขันสำเร็จ",
  参赛成功查看实时赛况:
    "เข้าร่วมแข่งขันสำเร็จ คุณสามารถดูผลการแข่งขันได้อย่างเรียลไทม์",
  往届回顾: "รีวิวการแข่งขันครั้งที่ผ่านมา",
  第一届交易大赛颁奖典礼: "พิธีมอบรางวัลการแข่งขันครั้งที่ 1",
  第一届交易大赛高光时刻: "ไฮไลท์การแข่งขันครั้งที่ 1",
  第一届交易大赛巅峰对决: "ศึกสุดยอดแห่งการแข่งขันเทรดครั้งที่ 1",
  查看更多: "ดูเพิ่มเติม",
  常见问题: "คำถามที่พบบ่อย",
  qaList: [
    {
      t: `1. "บัญชี" กับ "หมายเลขบัญชี"`,
      c: [
        "บัญชี: หมายถึงบัญชีที่คุณใช้เข้าสู่ระบบในศูนย์ผู้ใช้ EBC (UID) ซึ่งคุณสามารถมีได้เพียงบัญชีเดียวหลังจากที่คุณทำการยืนยันตัวตนเสร็จเรียบร้อยแล้ว",
        "หมายเลขบัญชี: หมายถึงหมายเลขบัญชีการเทรดในระบบ MT (MT trading account) ที่สามารถเปิดได้หลายบัญชีภายใต้บัญชีผู้ใช้ EBC เดียวกัน เพื่อใช้ในการเทรดจริง",
      ],
    },
    {
      t: "2. วิธีการตรวจสอบว่า คุณเข้าร่วมการแข่งขันสำเร็จแล้วหรือไม่?",
      c: [
        "เมื่อคุณเข้าร่วมการแข่งขันสำเร็จแล้ว หน้ากิจกรรมในศูนย์ผู้ใช้จะปรากฏข้อมูลบัญชีของคุณที่เข้าร่วมและแสดงอันดับปัจจุบันของคุณ",
      ],
    },
    {
      t: "3. สามารถใช้หลายบัญชีในการเข้าร่วมการแข่งขันได้หรือไม่?",
      c: [
        "ผู้เข้าแข่งขันสามารถใช้หลายบัญชีเทรดในการแข่งขัน และในแต่ละบัญชีเทรด สามารถเข้าร่วมได้เพียงรายการเดียวเท่านั้น",
      ],
    },
    {
      t: "4. หากได้รับรางวัลทั้ง 2 รายการ สามารถรับรางวัลได้ทั้ง 2 รางวัลเลยหรือไม่?",
      c: [
        "สามารถรับรางวัลได้ทั้ง 2 รายการ หากเข้าร่วมการแข่งขันทั้ง 2 รายการ และจะได้รับรางวัลตามข้อกำหนดที่ระบุ",
      ],
    },
    {
      t: "5. หากมีบัญชี MT4 อยู่แล้ว สามารถเข้าร่วมการแข่งขันได้หรือไม่?",
      c: [
        "ได้ หากบัญชี MT4 สร้างขึ้นในหรือหลังวันที่ 22 ก.พ. 2025 เวลา 01:00:00 (เวลา MT4/MT5) และต้องไม่มีประวัติการเทรดมาก่อน",
      ],
    },
    {
      t: "6. หากเข้าร่วมรายการ New Star Challenge แล้ว และฝากเงินมากกว่า 10,000$ จะต้องเปลี่ยนรายการแข่งขันหรือไม่?",
      c: [
        "รายการแข่งขันที่คุณเลือกจะไม่เปลี่ยนแปลง หลังจากที่คุณลงทะเบียนเข้าร่วมการแข่งขันในครั้งแรก แม้ยอดเงินในบัญชีจะมีการเปลี่ยนแปลงภายหลังก็จะไม่กระทบต่อรายการการที่คุณเลือก",
      ],
    },
    {
      t: "7. วิธีการตรวจสอบอันดับการแข่งขัน?",
      c: [
        "การจัดอันดับการแข่งขันจะอัปเดตทุกชั่วโมง (โดยประมาณ) และสามารถดูได้ในหน้ากิจกรรมการแข่งขัน",
      ],
    },
    {
      t: "8.ในระหว่างการแข่งขันสามารถฝากหรือถอนเงินได้หรือไม่?",
      c: [
        "สามารถฝากและถอนเงินได้ตามต้องการ แต่การฝากเงินจะมีผลต่อการคำนวณอัตราผลตอบแทน ROI = มูลค่าหลังหักลบกำไรหรือขาดทุน / ยอดฝากคงเหลือ",
      ],
    },
    {
      t: "9. วิธีการเงินรับรางวัลจากการโปรแกรมแนะนำเพื่อน?",
      c: [
        "รางวัลโปรแกรมแนะนำจะถูกโอนเข้าบัญชีของคุณภายใน 30 วันหลังจากที่ผู้ถูกเชิญทำการเทรดครั้งแรกเสร็จสิ้น จากนั้นคุณสามารถยื่นคำขอถอนเงินได้ และรางวัลจะถูกดำเนินการเมื่อสอดคล้องกับเงื่อนไข",
      ],
    },
    {
      t: "10. ทำไมระหว่างการแข่งขันผู้ที่ได้รับการเชิญไม่สามารถเปลี่ยนตัวแทนได้?",
      c: [
        "เพื่อความยุติธรรม ผู้ที่ได้รับเชิญต้องเข้าร่วมผ่านลิงก์ที่ได้รับการแนะนำ และไม่สามารถเลือกตัวแทนได้ เพื่อป้องกันการรับค่าคอมมิชชันและรางวัลซ้ำ หลังจากกิจกรรมเสร็จสิ้น สามารถติดต่อฝ่ายบริการลูกค้า เพื่อเปลี่ยนตัวแทนได้",
      ],
    },
  ],
};
