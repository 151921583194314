export default {
    "h1-title": "MT4便利ツール",
    "imgUrlLang": "-en",
    "public-btn-text": "今すぐ登録",
    screen0: {
        "title": "お取引の際に便利に使えるツールをご紹介いたします。",
    },
    screen1: {
        "title": "オーバーレイチャートインジケーター",
        "desc": "2つの通貨の指定期間内におけるプライスを重ねて表示することで、値動きの相対的な強さを表示することができます。",
    },
    screen2: {
        "title": "MACDインジケーター（二重線）",
        "desc": "株や先物取引でよく使われる指標です。MT4に含まれるMACDよりも、二重線のほうがより使いやすくなっています。",
    },
    screen3: {
        "title": "MTDriver 損益統計インジケーター",
        "desc": "各期間における損益を正確に把握するのに役立ちます。",
    },
    screen4: {
        "title": "価格変更モバイルプッシュ通知",
        "desc": "24時間ずっとマーケットを見る必要はありません。価格変動がスマートフォンに通知され、いち早く市場の動向を把握できます。",
    },
    screen5: {
        "title": "ワンクリックでEAを閉じる",
        "desc": "操作インターフェースはチャートインターフェースにあり、極端な市場状況が発生した場合に投資家がより迅速かつ便利に損失をストップするのに便利です。",
    },
    screen6: {
        "title": "ワンクリック決済EA",
        "desc": "操作ボタンはチャート画面上にあり、極端な相場状況が発生した際に、投資家がより迅速かつ簡単に決済を行い、損失を防ぐのに役立ちます。",
    },
    screen7: {
        "title": "時間指定注文EA",
        "desc": `指定した時間に注文を行う EA です。\\MQL4\\Experts フォルダに入れると簡単にお使いいただけます。`,
    }
}