export default {
  "box-name": "en-trading-body",
  lang: "en",
  了解指数差价合约: "Learn Index CFDs",
  "public-btn-text1": "View trading hours",
  "public-btn-text2": "All Products",
  screen0: {
    title: "Top Trading Products at EBC",
    desc: "Trade over 100 popular financial instruments with competitive pricing. Access liquid markets and capitalise on 24/7 opportunities.",
    btn: "Learn More",
  },
  screen1: {
    link1: "Forex Trading",
    link2: "Commodities",
    link3: "Index CFDs",
    link4: "Stock CFDs",
  },
  screen2: {
    title: "Forex Trading",
    text1: "Flexible leverage up to 500:1",
    text2: "Average execution speed 20ms",
    imgUrl: "trade-01",
    title2: "Trade as little as 0 pips<br>36 currency pairs",
    desc2:
      "Trading in the global derivatives market has jumped to the highest-ever level at $6.6 trillion per day in Sep. 2019, according to the Bank for International Settlements (BIS) Triennial Central Bank Survey.",
  },
  screen3: {
    title: "Commodities",
    text1: "Flexible leverage",
    text2: "Ultra-competitive spreads",
    text3: "Flexible open position transaction",
    title2:
      "If you want to diversify your investment portfolio, commodities will be your ideal choice",
    desc2: "Precious metals, crude oil, natural gas, etc.",
  },
  screen4: {
    title: "Index CFDs",
    text1: "Flexible pricing",
    text2: "20ms Average execution speed",
    text3: "Flexible leverage up to 100:1",
    text4: "No hidden commission or transaction costs",
    title2:
      "Meet the world's major stock indices Actively seeking countless opportunities",
  },
  screen5: {
    title: "Stock CFDs",
    text1: "Highly Competitive Trading Costs  ",
    text2:
      "Curated Selection of 51 Popular US Stocks",
    text3: "Exceptional Liquidity Support",
    text4: "Two-way Trading Opportunities",
    title2: "Get involved in some of the world's leading brands",
  },
};
