export default {
	"box-name": "partner2-page-en",
	"section1": {
		"text1": "パートナーシップ",
		"text2": "当社はコンテンツクリエイター、インフルエンサー、エージェントがつながるエコシステムを構築し、トレーダーの皆様がグローバルに活躍できる機会を提供しています。",
		"text3": "20カ国以上で業務展開",
		"text4": "業界トップの専門家",
		"text5": "多様なパートナーシッププラン",
		"text6": "様々なサポートシステム",
	},
	"section2": {
		"text1": "パートナーシッププラン",
		"text2": "IB",
		"text3": "業界教育者、プロ/アマチュアトレーダー、機関投資家",
		"text4": "<li>ビジネスサポート</li><li>ブランド認知拡大</li><li>トレードリソースとマーケティングサポート</li>",
		"text5": "IB登録をする",
		"text6": "CPAマーケティングパートナー",
		"text7": "ブロガー、インフルエンサー、オンラインマーケター",
		"text8": "<li>リワード</li><li>オーダーメードのパートナーシッププラン</li><li>マルチメディアマーケティングサポート</li>",
		"text9": "マーケティングパートナーになる",
		"text10": "ストラテジスト",
		"text11": "プロのアナリスト、アマチュアトレーダー、ストラテジスト",
		"text12": "<li>ギフト</li><li>オンラインとオフラインのマーケティング活動</li><li>トレーディングリソースおよびマーケティングサポート</li>",
		"text13": "ストラテジーサポート",
	},
	"section3": {
		"text1": "パートナーサポート",
		"text2": "メディアへの露出",
		"text3": "知識を提供し、パートナーのブランド認知を高める",
		"text4": "ネットワーキング",
		"text5": "業界専門家との交流を通して市場の動向を知る",
		"text6": "マーケティングサポート",
		"text7": "マーケティング材料の提供、キャンペーンサポート、プロモーション戦略",
		"text8": "シニアアナリストトレーニング",
		"text9": "スキル及び専門性のブラッシュアップが含まれる",
		"text10": "オーダーフロー",
		"text11": "主要なフローを発見することで投資勝率を向上させ、プロの投資家に愛用されているツール",
		"text12": "専門知識の拡大",
		"text13": "コンテンツライターへの協力を通じて、影響力と専門性を強める",
	},
	"section4": {
		"text1": "パートナーシップ代表",
		"text2": "Joe DiNapoli",
		"text3": "<li>フィボナッチのゴッドファーザー</li><li>DiNapoli Levels創設者</li><li>FibNodesソフトウェアの開発者</li>",
		"text4": "ジョー・ディナポリ氏は55年のキャリアを誇る投資家で、1987年のダウ大暴落や2002年の相場の谷など、市場の転換点を的確に予測した。25年以上CTAとして活躍する彼は、13ヶ国語に翻訳された人気の著書『ディナポリ・レベルズ』でよく知られている。",
		"text5": "詳しくはこちら",
		"text6": "<li>ローレンスは商業建築業界の出身だが、より充実したキャリアを求めて金融業界に転身した。ジョー・ディナポリの「ディナポリ・レベルズ」に出会ってから、彼のトレーディングアプローチは一変した。17年前にバンコクで開催されたジョーのセミナーに参加して以来、ローレンスは一貫して市場の動きを予測し続け、コロナによる市場の暴落も見事に乗り切った。現在はディナポリ・エキスパートとして、新人トレーダーへ、陥りがちな落とし穴を避けながら、学習を効率的に行う方法の指導をしている。</li>",
		"text7": "<li>ピーターは高校時代から投資に興味を持っていた。大学を優秀な成績で卒業後、エコノミストとして、南アフリカの金融機関でフルタイムでトレーディングを開始し、様々な商品を取引した。2003年からディナポリ研究を始め、2009年からは世界中でディナポリ・メソッドを教えている。現在、彼は自分の口座を管理しつつ、南アフリカのヘッジファンドの一員であり、南アフリカのコモディティ会社のアドバイザーでもある。また、ニュースレター「D-Levels Financial Forecast」の著者でもある。彼の成功の多くは、ジョー・ディナポリの指導によるものである。</li>",
		"text8": "<li>モンチャイ・コンタナパクディは、タイのテクニカル・アナリスト協会の創設者であり、CMT協会タイ支部の共同議長、またタイの投資家向けにテクニカル分析を指導、訓練するために設立された組織であるEfuturesthaiトレーディング・アカデミーの代表でもある。 フィボナッチ（ディナポリ・レベルズ）とアルゴリズム取引を専門とするフルタイムトレーダー、テクニカル・アナリスト、そして投資家であるモンチャイは2011年以来、ジョー・ディナポリ氏と共にいくつもの公開/プライベートセミナーをタイで開催している。2022年にタイで出版された「Trading with DiNapoli Levels（タイ語版）」の翻訳も担当した。</li>",
		"text9": "<li>米国のフィボナッチ・インベストメント・コンサルティング社社長、ニューヨーク金融協会 北京支店およびシカゴ商品取引所客員講師、中国先物日報社主催の全国リアルマネー・トレーディング・コンテストにおける金賞メンター、中国語版「Trading with DiNapoli Levels」の翻訳者。 ディナポリ・レベルのシニア・エキスパートとして、2008年以降の上海総合指数のほぼすべての転換点、およびダウ・ジョーンズ指数、米ドル指数、米国原油先物、米国金先物のいくつものトップとボトムの予測に成功している。</li>",
	},
	"section5": {
		"text1": "過去イベント",
		"text2": "伝説のトレーダー、ディナポリが7月12日にバンコクで行われたEBC Financial Groupとのパートナーシップ調印式に出席しました。",
		"text3": "取引戦略の共有",
		"text4": "世界中のメディアや投資家が注目する中、ディナポリ氏らが最先端のトレーディング戦略を解説しました。",
		"text5": "直筆サイン本をその場でプレゼント",
		"text6": "ディナポリはその場で限定サイン本を配り、多くの来場者と交流をしました。",
		"text7": "双方の知名度を高める",
		"text8": "このパートナシップは取引の専業化を進化させ、業界での知名度と影響力を高めました。",
	},
}