export default {
  linkList: [
    {
      title: "Account & Conditions",
      list: [
        {
          label: "Trading Accounts",
          link: "/TradingAccount",
        },
        {
          label: "Trading Products",
          link: "/tradingproduct",
        },
        {
          label: "Leverage & Margin",
          link: "/leverage-margin",
        },
        {
          label: "Deposit & Withdrawals",
          link: "/funding",
        },
        {
          label: "Dividend",
          link: "/dividend",
        },
      ],
    },
    {
      title: "Trading Tools",
      list: [
        {
          label: "MT4 Download",
          link: "/MT4",
        },
        {
          label: "MT5 Download",
          link: "/MT5",
        },
        {
          label: "MT4 Trading Tools",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "Market Analysis",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "EBC Institute",
      list: [
        {
          label: "Learning Centre",
          link: "/LearningCenter",
        },
        {
          label: "Data Center",
          link: "/lab",
        },
      ],
    },
    {
      title: "Activities",
      list: [
        {
          label: "Market Activities",
          link: "/activity?index=0",
        },
        {
          label: "Brand Updates",
          link: "/activity?index=1",
        },
        // {
        //   label: "Trading Contest",
        //   link: "/win",
        // },
        // {
        //   label: "Creative Program",
        //   link: "/cfa",
        // },
        // {
        //   label: "Salary Scheme",
        //   link: "/pay",
        // },
      ],
    },

    {
      title: "About EBC",
      list: [
        {
          label: "WHY EBC",
          link: "/about-us",
        },
        {
          label: "Legal Documents",
          link: "/LegalDocumen",
        },
        {
          label: "Sustainability & Impact",
          link: "/ESG",
        },
        {
          label: "FAQ",
          link: "https://support.ebchelp.com/hc/en-001",
        },
        {
          label: "Official Channel Verification",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "Partners",
      list: [
        {
          label: "Affiliate Programmes",
          link: "/affiliate-programmes",
        },
        {
          label: "Institutional Services",
          link: "/service",
        },
        {
          label: "Liquidity Solutions",
          link: "/technology",
        },
      ],
    },
  ],
  context: `
      <p>
        <span class="page-footer-intro-blod">
          EBC Financial Group is a co-brand shared by a group of entities
          including:<br />
        </span>

        EBC Financial Group (SVG) LLC is authorized by the St.Vincent and the
        Grenadines Financial Services Authority(SVGFSA),and the company
        registration number is 353 LLC 2020, with registered address at Euro
        House, Richmond Hill Road, Kingstown, VC0100, St. Vincent and the
        Grenadines.<br />

        EBC Financial Group (Comoros) Limited is authorised by The Autonomous
        Island of Anjouan, Union of Comoros Offshore Finance Authority with
        License number L 15637/EFGC, with registered office address at Hamchako,
        Mutsamudu, Autonomous Island of Anjouan, Union of Comoros.
      </p>
      <p>
        <span class="page-footer-intro-blod"
          >Other Relevant Entities<br
        /></span>
        EBC Financial Group (UK) Limited is authorised and regulated by the
        Financial Conduct Authority. Reference Number: 927552. Website:<a
          target="_blank"
          href="https://www.ebcfin.co.uk"
          rel="nofollow"
          >www.ebcfin.co.uk</a
        >
      <br />
        EBC Financial Group (Cayman) Limited is licensed and regulated by the
        Cayman Islands Monetary Authority (Number: 2038223). Website:
        <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow">
          www.ebcgroup.ky</a
        >
        <br />

        EBC Group (Cyprus) Ltd, faciliates payment services to the licensed and
        regulated entities within the EBC Financial Group strucutre, registered
        under the Companies Law of Republic of Cyprus with the number HE 449205,
        registered office address at 101 Gladstonos, Agathangelou Business
        Centre, 3032 Limassol, Cyprus.
        <br />
        EBC Financial Group (Australia) Pty Ltd (ACN: 619 073 237) is authorised
        and regulated by the Australian Securities and Investments Commission
        (Number: 500991). EBC Financial Group (Australia) Pty Ltd is a related
        entity of EBC Financial Group (SVG) LLC. The two entities are managed
        separately. The financial products and services offered on this website
        are NOT provided by the Australian entity and no recourse against the
        Australian entity is available.
      </p>
      <p>
        <span class="page-footer-intro-blod"> Business Address: </span>
        The Leadenhall Building, 122 Leadenhall Street, London, United Kingdom,
        EC3V 4AB. Email Address :cs@ebc.com . Telephone : +44 20 3376 9662
      </p>
      <p>
        <span class="page-footer-intro-blod">Disclaimer:</span>EBC Financial
        Group explicitly does not participate in any cryptocurrency activities
        or offer Crypto Contracts for Difference (CFDs). Our financial services
        solely pertain to traditional instruments. Clients are advised that any
        references to crypto-related services using the EBC name are inaccurate
        and unauthorized. Any reference to past performance is not indicative of
        future performance. The information contained in this website is
        provided for reference only and does not constitute any investment
        advice.
      </p>
      <p>
        <span class="page-footer-intro-blod">Regional Restrictions:</span>EBC
        does not offer any services to citizens and residents of certain
        jurisdictions including: Afghanistan, Belarus, Burma (Myanmar), Canada,
        Central African Republic, Congo, Cuba, Democratic Republic of the Congo,
        Eritrea, Haiti, Iran, Iraq, Lebanon, Libya, Malaysia, Mali, North Korea
        (Democratic People's Republic of Korea), Russia, Somalia, Sudan, South
        Sudan, Syria, Ukraine (including Crimea, Donetsk, and Luhansk Regions),
        the United States, Venezuela, and Yemen.
        <br />
        Any Spanish on this website is for LATAM only and is not designated for
        anyone in European Union or Spain For more information, please check out
        our FAQs.
      </p>
      <p>
        <span class="page-footer-intro-blod">Compliance Disclosure：</span>The
        website can be accessed globally and is not specific to any entity. Your
        actual rights and obligations will be determined based on the entity and
        jurisdiction that you choose to be regulated.There may be local laws and
        regulations which prohibit or limit your rights to access, download,
        distribute, disseminate, share or otherwise use any or all of the
        documents and information published on this website.
      </p>
      <p>
        <span class="page-footer-intro-blod">Risk Warning:</span>Trading
        Contracts for Difference (CFDs) are complex financial instruments and
        come with a high risk of losing money rapidly due to leverage. Trade on
        margin carries a high level of risk and may not be suitable for all
        investors. Before deciding to trade Forex and CFDs, you should carefully
        consider your trading objectives, level of experience and risk appetite.
        The possibility exists that you could sustain a loss of some or all of
        your initial trading capital. We recommend that you seek independent
        advice and ensure you fully understand the risks involved before making
        any investment decision. Please read the relevant risk disclosure
        statements carefully before trading.
      </p>
  `,
};
