export default {
  "box-class-name": "en-box",
  "lang": "ja",
  "MT4是最受欢迎的交易平台之一": "MetaTrader 4 (MT4)は、世界で最も人気のある取引プラットフォームの一つであり、その優れた技術、使いやすさ、安全性により、世界中のトレーダーから広く認知されています。",
	"内核稳定流畅": "正確な取引戦略を立てるのに必要なチャート分析機能を提供",
	"支持EA交易": "自動売買のためのEA取引をサポート",
	"活跃用户社区": "多様なテクニカル指標と分析ツールを搭載",
	"立即注册": "今すぐ口座登録",
  "MetaTrader 4下载": "MetaTrader 4ダウンロード",
  "MT4移动版": "MT4モバイル版",
  "随时随地进行交易": "いつでもどこでも取引可能！市場動向の把握、口座管理をより簡単に。",
  "下载Android版": "アンドロイド版",
  "下载IOS版": "iOS版",
  "下载iPad版": "iPad版",
  "MT4桌面版": "MT4デスクトップ版",
  "全面的交易工具和高级图表分析": "総合的な取引ツールと高度なチャート機能でプロトレーダーのニーズに応えます。",
  "下载Windows版": "Windows版",
  "下载MacOS版": "MacOS版",
  "MT4网页版": "MT4 ウェブ版",
  "无需下载": "ダウンロード不要で世界市場に高速アクセス可能。オンライン取引が簡単に管理できます。",
  "打开网页版": "ウェブ版を開く",
}