export default {
  title: `<span class="ebc">EBC</span>
                    <span class="text pc">全球百萬美金交易大賽Ⅱ</span>
                    <span class="text mobile">全球百萬美金</span>`,
  title2: `<h1 class="title cn-font-family-title" style="margin-top:20rem">交易大賽Ⅱ</h1>`,
  巴萨传奇再启: "巴薩傳奇再啟",
  成就交易之王: "成就交易之王",
  大赛时间: "比賽時間：2025年3月1日 01:00:00-2025年5月30日 23:59:59 (GMT+3)",
  距开赛: `距<br />開<br />賽`,
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  一键跟单: "一鍵跟單",
  免费复制冠军收益: "免費複製冠軍收益",
  公开透明: "公開透明",
  观摩冠军交易技巧: "觀摩冠軍交易技巧",
  推荐有礼: "推薦有禮",
  推荐有奖上不封顶: "推薦有獎不設上限",
  立即参赛: "立即參賽",
  现金奖池: "現金獎池",
  巴萨梦之队: "巴薩夢之隊",
  拉玛西亚组: "拉瑪西亞組",
  冠军: "冠軍",
  亚军: "亞軍",
  季军: "季軍",
  第四至五名: "第四至五名",
  第六至十名: "第六至十名",
  "$1,000,000交易账号或$200,000现金": "$1,000,000交易帳號或$200,000現金",
  "1,000,000交易账号或200,000现金": "$1,000,000交易帳號或$200,000現金",
  大赛推荐官: "大賽推薦官",
  推荐奖励: "交易即享推薦獎勵 不設上限",
  巴萨梦之队HTML: `<span>巴</span><span>薩</span><span>夢</span><span>之</span><span>隊</span>`,
  拉玛西亚组HTML: ` <span>拉</span><span>瑪</span><span>西</span><span>亞</span><span>組</span>`,
  "/人": "/人",
  活动时间:
    "活動時間：  2025年2月22日 01:00:00（MT4/MT5時間）至2025年5月23日 23:59:59（MT4/MT5時間）",
  推荐流程: "3步推薦 交易立享",
  推荐好友: "推薦好友",
  好友参赛: "好友交易",
  赢得奖金: "贏得獎金",
  实时赛况: "即時賽況",
  排名: "排名",
  昵称: "暱稱",
  服务器: "伺服器",
  账号: "帳號",
  收益额: "收益額",
  收益率: "收益率",
  奖金: "獎金",
  跟单: "跟單",
  该页面仅展示收益表现排名前10的交易账号:
    "*該頁面僅展示收益表現排名前十的交易帳號，排名貨幣單位為美金（USD），每小時更新",
  赛事进程: "賽事進程",
  比赛开始: "比賽開始",
  推荐结束: "推薦結束",
  比赛结束: "比賽結束",
  颁奖典礼: "頒獎典禮",
  赛组规则: "賽組規則",
  类别: "類別",
  账号要求: "帳號要求",
  "新交易账号，且账号余额大于$500": "新交易帳號，且帳號餘額≥$500。",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "新交易帳號，且$10,000 ≤ 帳號餘額 ≤ $200,000。",
  排名规则: "排名規則",
  以收益率排名为评判标准: "以收益率排名為評判標準",
  "收益率=期末净值/账号累积入金": "收益率 = 期末淨值 / 帳號累積入金",
  以净盈利额排名为评判标准: "以淨盈利額排名為評判標準",
  持仓限制: "持倉限制",
  每个参赛账号最多持有100手仓位: "每個參賽帳號最多持有100手倉位。",
  无持仓限制: "無持倉限制",
  参赛条款: "參賽條款",
  参赛须知: "參賽須知",
  暂未开赛:"暫未開賽，敬請期待。",
  section6List: [
    {
      t: "1、活動時間",
      c: [
        "2025年3月1日 01:00:00（MT4/MT5時間）至2025年5月30日 23:59:59（MT4/MT5時間）",
      ],
    },
    {
      t: "2、帳號要求",
      c: [
        "參賽帳號需在交易社群中發布信號，並允許免費跟隨。",
        "參賽者可使用多個帳號參賽，但僅按表現最優的帳號計入排名。",
        "僅限STD與PRO帳號參賽，PAMM帳號及伊斯蘭帳號不具備參賽資格。",
      ],
    },
    {
      t: "3、獎勵規則",
      c: [
        "冠軍獎勵：若冠軍選擇100萬美金交易帳號獎勵，最大虧損限額為20萬美金。",
        "其他獎項均按照排名進行獎勵",
      ],
    },
    {
      t: "4、禁止行為",
      c: [
        "嚴禁透過帳號間套利交易提高收益，任何透過多帳號交叉持倉、避險交易及濫用報價等違規行為，都將取消參賽資格。",
        "因伺服器硬體故障、報價錯誤等不可控因素導致的訂單視為無效。",
      ],
    },
    {
      t: "5、最終解釋權",
      c: ["EBC金融集團擁有本次活動最終解釋權。"],
    },
  ],
  参赛流程: "參賽流程",
  注册开户: "註冊開戶",
  注册并开设新交易账号: " 註冊並開設新交易帳號",
  报名参赛: "報名參賽",
  在用户中心报名参赛: "在用戶中心報名參賽",
  参赛成功: "參賽成功",
  参赛成功查看实时赛况: "參賽成功後可查看即時賽況",
  往届回顾: "歷屆回顧",
  第一届交易大赛颁奖典礼: "第一屆交易大賽頒獎典禮",
  第一届交易大赛高光时刻: "第一屆交易大賽高光時刻",
  第一届交易大赛巅峰对决: "第一屆交易大賽巔峰對決",
  查看更多: "查看更多",
  常见问题: "常見問題",
  qaList: [
    {
      t: "1．「帳戶」與「帳號」的區別",
      c: [
        "帳戶：指EBC用戶中心帳戶（UID），每位用戶經過實名認證後只能擁有一個帳戶。",
        "帳號：MT交易帳號，每個EBC帳戶可創建多個交易帳號用於入金和交易。",
      ],
    },
    {
      t: "2．如何確認參賽成功？",
      c: ["參賽成功後，用戶中心大賽活動頁面會顯示參賽帳號及當前排名。"],
    },
    {
      t: "3．是否可以多帳號參賽？",
      c: [
        "同一用戶可分別在兩個賽組使用多個帳號參賽，但每個帳號只能參加一個賽組。",
      ],
    },
    {
      t: "4．兩個賽組同時獲獎是否可同時領獎？",
      c: ["可同時領取兩個賽組的獎勵，前提是兩個組別均合規完成比賽並獲獎。"],
    },
    {
      t: "5．已有MT4帳號可否參賽？",
      c: [
        "2025年2月22日 01:00:00（MT4/MT5時間）後開設的，且沒有歷史交易記錄的MT帳號。",
      ],
    },
    {
      t: "6．參賽拉瑪西亞組後，再次入金超過1萬美金會修改參賽組別嗎？",
      c: ["不會，參賽組別以參賽報名時為準，後續資金變動不影響組別。"],
    },
    {
      t: "7．如何查看比賽排名？",
      c: [
        "排名每小時更新（非整點更新），可在用戶中心大賽活動頁查看自己的排名。",
      ],
    },
    {
      t: "8．比賽期間能否出入金？",
      c: [
        "可自由出入金，但入金會影響收益率計算（收益率 = 期末淨值 /帳號累積入金）。",
      ],
    },
    {
      t: "9．如何申請領取邀請獎勵？",
      c: [
        "邀請獎勵將在受邀者符合條件的帳戶完成第一筆開倉交易後的30天內發放至獎金餘額。屆時，您可申請提現，審核通過後獎勵入帳。",
      ],
    },
    {
      t: "10．比賽期間為何「被邀請」者不能轉移代理？",
      c: [
        "為確保公平，「被邀請」者需通過推薦鏈接參與且不能指定代理，避免重複領取佣金和獎金。活動結束後可聯繫客服綁定代理。",
      ],
    },
  ],
};
