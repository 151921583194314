export default {
    "box-class-name": "en-box",
    "lang": "ja",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTrader 5（MT5）は、世界中のトレーダーの多様なニーズに応えるため、より高度な取引機能と分析ツールを統合した次世代のマルチアセット取引プラットフォームです。",
    "提供市场深度信息": "市場の流動性をよりよく理解できるよう、詳細な板情報を提供",
    "支持6种挂单交易类型": "6種類の指値注文方法に対応し、様々な取引ニーズに柔軟に対応",
    "内置经济日历": "世界の重要な経済ニュースをリアルタイムで更新する経済カレンダーを搭載",
    "立即注册": "今すぐ口座登録",
    "MetaTrader 5下载": "MetaTrader 5ダウンロード",
    "MT5移动版": "MT5モバイル版",
    "随时随地进行交易": "いつでもどこでも取引可能！市場動向の把握、口座管理をより簡単に。",
    "下载Android版": "アンドロイド版",
    "下载IOS版": "iOS版",
    "下载iPad版": "iPad版",
    "MT5桌面版": "MT5デスクトップ版",
    "全面的交易工具和高级图表分析": "総合的な取引ツールと高度なチャート機能でプロトレーダーのニーズに応えます。",
    "下载Windows版": "Windows版",
    "下载MacOS版": "MacOS版",
    "MT5网页版": "MT5 ウェブ版",
    "无需下载": "ダウンロード不要で世界市場に高速アクセス可能。オンライン取引が簡単に管理できます。",
    "打开网页版": "ウェブ版を開く",
    "MT4与MT5的区别": "MT4とMT5の違い",
    "MT4和MT5均由MetaQuotes开发": "両者ともMetaQuotes社が開発したものですが、MT5は単純なアップグレードというわけではありません。両者は機能面でも適用範囲でも大きく異なります。",
    "项目": "項目",
    "发布时间": "リリース時期",
    "2005年": "2005年",
    "2010年": "2010年",
    "交易市场": "取引市場",
    "主要是衍生品": "主にFX",
    "包括股票": "株式、先物、FXなど",
    "挂单类型": "指値注文方法",
    "4种": "4 種類",
    "6种": "6 種類",
    "时间框架": "時間足数",
    "9种": "9",
    "21种": "21",
    "技术指标": "指標数",
    "30个": "30",
    "38个": "38",
    "市场深度":"板注文画面",
    "不支持":"なし",
    "支持":"あり",
    "财经日历":"経済指標カレンダー",
    "内置":"あり",
    "操作界面":"操作画面",
    "更直观简单":"シンプルでわかりやすい",
    "更加现代化灵活":"高度かつ柔軟"
}