export default {
  leftNav: [
    { id: "#Trading", name: "交易" },
    { id: "#Regulation", name: "顶级监管" },
    { id: "#Security", name: "安全保障" },
    { id: "#Fintech", name: "金融科技" },
    { id: "#Black-Box", name: "交易黑盒" },
    { id: "#Private-Room", name: "交易空间" },
    { id: "#Liquidity", name: "流动性" },
    { id: "#Honor", name: "国际荣誉" },
    //{ "id": "#News", "name": "新闻" }
  ],
  pages1: {
    h1: "Trust & Respect",
    title: "每个认真交易的人，都值得被认真对待",
    btn: " 观看全视频 ",
  },
  pages2: {
    title: "连接全球热门交易产品",
    title1: "探索一个充满机遇和竞争优势的世界，掌握最真实和稳定的市场价格",
    btn: " 了解交易产品 ",
    text: " 货币交易 ",
    text1: " 大宗商品 ",
    text2: " 现货指数差价合约 ",
    text3: " 股票差价合约 ",
  },
  pages3: {
    title: "全球顶级监管",
    title1:
      "EBC金融集团旗下各公司，在对应司法管辖区域内，均受到严格监管并获得顶级资质许可。",
    btn: " 了解EBC监管 ",
    text: " 英国 FCA 监管 ",
    text1:
      " EBC Financial Group (UK) Ltd<br class='regulation-br'> 由英国金融行为监管局 (FCA) 授权和监管。<br class='regulation-br'> 监管号: 927552 ",
    text2: " 澳洲 ASIC 监管 ",
    text3:
      "EBC Financial Group (Australia) Pty Ltd <br class='regulation-br'>由澳大利亚证券和投资委员会 (ASIC) 授权和监管。<br class='regulation-br'>监管号: 500991",
    text4: " 开曼 CIMA 监管 ",
    text5:
      "EBC Financial Group (Cayman) Ltd<br class='regulation-br'>由开曼群岛金融管理局(CIMA)授权和监管。<br class='regulation-br'>监管号: 2038223",
  },
  pages4: {
    title: "科技赋能交易",
    title1:
      "部署于伦敦LD4、纽约NY4、新加坡SG1、东京TY3、香港HK2的超高速专线连接的独立服务器，提供超低延迟聚合、智能订单路由和报价优化引擎，构建全球超高速金融生态体。",
    btn: " 探索底层科技",
    text: " 每秒最高聚合订单 ",
    text1: " 极速订单执行 ",
    text2: " 数据传输稳定高达 ",
    text3: " 同业银行级别的RAW ECN点差",
  },
  pages5: {
    title: "让85%以上的订单成交于更优的价格",
    title1:
      "EBC交易黑盒，精准优化抛单算法，在价格优化率、订单成交率以及算法稳定性方面， EBC交易黑盒远远优于传统交易系统。",
    btn: " 了解交易黑盒",
  },
  pages6: {
    title: "EBC交易空间",
    title1:
      "无论您是高频交易者，还是大单交易家，EBC 的 Private Room 都将在流动性匹配基础上，搭建您的专属的交易通道，甚至隐藏您的部分交易信息，保护您的“底牌”，这意味着您的订单不仅能获得更好的聚合报价，也可以在成交过程中免于狙击。",
    btn: " 了解交易空间",
  },
  pages7: {
    title: "国际顶级流动性",
    title1:
      "EBC与多家国际顶级银行建立了长期稳定的合作关系，整合多家优质流动性报价，提供极具竞争力的市场点差，让您轻松享有超低交易成本。",
    btn: " 了解EBC流动性",
  },
  pages8: {
    btn: "浏览更多新闻",
  },
  pages9: {
    title: "全面保障 安心以致",
    title1:
      "EBC将资金安全视为首要原则，客户资金独立存放于巴克莱银行的托管账户中，并通过与FCA、Financial Commission、Lloyds of London、AON合作，建立了全面、多层次的安全保障机制。",
    btn: "了解安全保障",
  },
  lang: "cn",

  连接全球热门交易产品: "连接全球热门交易产品",
  探索一个充满机遇和竞争优势的世界:
    "探索一个充满机遇和竞争优势的世界，掌握最真实和稳定的市场价格",
  衍生品交易: "衍生品交易",
  期货CFDs: "贵金属及能源",
  全球股票指数CFDs: "全球股票指数CFDs",
  美国股票CFDs: "美国股票CFDs",
  "欧元/美元": "欧元/美元",
  "黄金/美元": "黄金/美元",
  道琼斯工业指数: "道琼斯工业指数",
  了解交易产品: "查看更多交易产品",
  全球顶级监管: "全球顶级监管",
  EBC金融集团旗下各公司:
    "EBC金融集团旗下各公司，在对应司法管辖区域内，均受到严格监管并获得顶级资质许可。",
  授权及监管于: "授权及监管于",
  监管号: "监管号",
  英国FCA监管: "英国 FCA 监管",
  "英国金融行为监管局 (FCA)": "英国金融行为监管局 (FCA)",
  开曼CIMA监管: "开曼 CIMA 监管",
  "开曼群岛金融管理局(CIMA)": "开曼群岛金融管理局(CIMA)",
  澳洲ASIC监管: "澳洲 ASIC 监管",
  澳大利亚证券和投资委员会: "澳大利亚证券和投资委员会",
  了解EBC官方: "了解EBC官方",
  "全面保障 安心以致": "全面保障 安心以致",
  EBC将资金安全视为首要原则:
    "EBC金融集团始终将客户资金安全置于首位，EBC英国及开曼公司的客户资金均存放于全球顶级银行，通过严格遵循FCA、CIMA及ASIC监管要求，参与全球政府支持的保障性补偿计划，并通过伦敦劳合社获得多项公司级保险保障，构建起多层次防护体系，全面保障客户资金安全。",
  了解安全保障: "了解安全保障",
  "连接全球 信赖首选": "连接全球 信赖首选",
  EBC构建全方位服务生态:
    "EBC构建全方位服务生态，专家团队具有逾30年经验，业务遍布全球，拥有广泛的活跃用户，在国际舞台上备受赞誉。",
  全球注册用户数: "全球注册用户数",
  日均交易订单量: "日均交易订单量",
  "2024年第三季度为交易订单优化增益": "2024年第三季度为交易订单优化增益",
  多个: "+",
  "业务覆盖国家/地区": "业务覆盖国家/地区",
  了解全球视野: "了解全球视野",
  "85%以上": "85%以上",
  的订单成交于更优价格: "的订单成交于更优价格",
  EBC交易黑盒:
    "EBC交易黑盒，精准优化抛单算法，在价格优化率、订单成交率以及算法稳定性方面，EBC交易黑盒远远优于传统交易系统。",
  每秒聚合订单: "每秒聚合订单",
  极速订单执行: "极速订单执行",
  数据传输稳定高达: "数据传输稳定高达",
  "行级RAW ECN点差": "行级RAW ECN点差",
  了解金融科技: "了解金融科技",
  EBC全球合作伙伴: "EBC全球合作伙伴",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系，提供真正的机构流动性深度和0.0 Pips同业级别原始点差 。此外，EBC联合牛津大学、携手 WAGGGS、与联合国基金会合力，积极践行企业责任，构建平等、可持续发展的社会。 ",
  了解更多: "了解更多",
  十大最受欢迎券商: "十大最受欢迎券商",
  最透明交易券商: "最透明交易券商",
  最受好评券商: "最受好评券商",
  卓越执行券商: "卓越执行券商",
  全球最佳交易体验奖: "全球最佳交易体验奖",
  最受信赖券商: "最受信赖券商",
  最佳CFD券商: "最佳CFD券商",
  全球最佳ECN券商: "全球最佳ECN券商",
  全球新锐券商: "全球新锐券商",
  tabList: [
    `衍生品<br class="mobile" />交易`,
    `贵金属<br class="mobile" />及能源`,
    `全球股指CFDs`,
    `美国股票CFDs`,
  ],
  出售: "出售",
  买入: "买入",
  点差: "点差",
  立即交易: "立即交易",
  致力于让交易者成为盈家: "致力于让交易者成为盈家",
  EBC每个引以为傲的数据背后:
    "EBC每个引以为傲的数据背后，是全球100多个国家投资者的信任。EBC全方位服务生态，让每位交易爱好者一键连接全球机遇。",
  月均交易量: "月均交易量",
  "2024年第四季度为交易订单优化增益": "2024年第四季度为交易订单优化增益",
  number1: "1775",
  number1Text: "1775",
  number2: "400",
  number2Text: "400",
  number3: "273",
  number3Text: "273",
  number4: "100",
  number4Text: "100",
  langFlag: "cn",
  亿: "亿+",
  万: "万+",
  "+": "+",
};
